const ContactUsPage = {
  heading: 'Contact Us',
  title: 'Get in touch to get the ball rolling!',
  overview:
    "Don't worry, your email isn't going to be in inbox abyss, never to be seen or heard from again. We value your concerns. Old fashioned phone calls work too. We're all ears.",
  navigation: {
    prev: {
      title: 'Technology',
      overview:
        'From basics to path-breaking technologies, we hack all of them with sheer precision and excellence. We work on varied platforms and disruptive technologies',
    },
    next: {
      title: 'Industries',
      overview:
        'There is phenomenal digital transformation and emergence of automation in all spheres of business. We have worked across a vast range of industries and cater to the finest of technical expertise required for your business needs.',
    },
  },
};

export default ContactUsPage;
