import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { Layout } from 'layouts';
import { TitleHeader } from '@components';
import headerImage from '@static/header/codemymobile_website_2_blue_white_Contactus.jpg';
import pageContent from '@constants/contactUs';

const ContactUsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 90%;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
`;

const ContactUsWrapper = styled.div`
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ContactUsTitle = styled.p`
  color: ${props => props.theme.colors.primary.base};
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ContactUsText = styled.p`
  font-size: 14px;
  line-height: 26px;
  color: ${props => props.theme.colors.black.base};
`;

class ContactUs extends Component {
  render() {
    return (
      <Layout>
        <TitleHeader image={headerImage} title={pageContent.heading} />

        {this.renderPageContactUs()}
      </Layout>
    );
  }

  renderPageContactUs() {
    let text = pageContent.overview.split('\n');
    return (
      <ContactUsSection>
        <ContactUsWrapper>
          <ContactUsTitle>{pageContent.title}</ContactUsTitle>
          <ContactUsText>{text}</ContactUsText>
        </ContactUsWrapper>
      </ContactUsSection>
    );
  }
}

export default ContactUs;
